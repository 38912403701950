<script>
import form from './form'

export default {
  extends: form,

  created() {
    window.isLoading(true)

    this.$apiMethods.districts.get(this.districtId)
      .then(data => {
        this.form = data
      })
      .finally(() => { window.isLoading(false) })
  },

  computed: {
    dangerButtonText() {
      return 'Удалить округ'
    },

    districtId() {
      return this.$route.params.id
    }
  },

  methods: {
    onSubmit() {
      this.$apiMethods.districts.update(this.districtId, this.form)
        .then(() => {
          this.successSubmit({ message: 'Федеральный округ успешно изменен', routeName: 'districts' })
        })
        .finally(() => { this.afterSubmit() })
    },

    handleDeleteDistrict() {
      this.isSubmit = true

      this.$apiMethods.districts.delete(this.districtId)
        .then(() => {
          window.snackbar( 'Федеральный округ успешно удален', 'info')
          this.$router.push({ name: 'districts' })
        })
        .finally(() => { this.isSubmit = false })
    }
  }
}
</script>
